import BlueBasePluginMaterialUI from '@bluebase/plugin-material-ui';
import BlueBasePluginReactRouter from '@bluebase/plugin-react-router';
// import BlueEastClientPluignUI from '@blueeast/client-plugin-ui';
import { MaterialCommunityIcons } from '@bluebase/plugin-vector-icons';
import Plugin from '../../src';

export const plugins = [
	BlueBasePluginReactRouter,
	BlueBasePluginMaterialUI,
	// BlueEastClientPluignUI,
	MaterialCommunityIcons,
	Plugin,
];
