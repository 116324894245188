import { Body1, Body2, TouchableItem, View } from '@bluebase/components';
import { TextStyle, ViewStyle } from 'react-native';

import React from 'react';
import { Theme } from '@bluebase/core';
import { VariantSelectorItemProps } from '../VariantSelector';

export interface TextVariantSelectorItemStyles {
	root: ViewStyle;
	hover: ViewStyle;
	active: ViewStyle;
	title: TextStyle;
	description: TextStyle;
}

export interface TextVariantSelectorItemProps extends VariantSelectorItemProps {
	title: string;
	description?: string;
	styles?: Partial<TextVariantSelectorItemStyles>;
}

export class TextVariantSelectorItem extends React.Component<TextVariantSelectorItemProps> {
	static defaultProps: Partial<TextVariantSelectorItemProps> = {
		active: false,
		hovering: false,
		size: 'medium',
	};

	static defaultStyles = (theme: Theme): TextVariantSelectorItemStyles => ({
		root: {
			borderColor: theme.palette.divider,
			borderRadius: theme.spacing.unit,
			borderWidth: 2,
			paddingHorizontal: theme.spacing.unit * 2,
			paddingVertical: theme.spacing.unit * 1.5,
		},

		hover: {
			borderColor: '#aaa',
		},

		active: {
			borderColor: theme.palette.primary.main,
		},

		title: {
			fontWeight: 'bold',
		},

		description: {
			color: theme.palette.text.secondary,
			marginTop: theme.spacing.unit,
		},
	})

	handlePress = () => {
		const { index, onPress } = this.props;

		if (onPress) {
			onPress(index);
		}
	}

	render() {
		const { active, hovering, title, description, styles } = this.props;

		const rootStyle: ViewStyle = {
			...styles!.root,
			...(hovering ? styles!.hover : {}),
			...(!!active ? styles!.active : {}),
			// ...style,
		};

		return (
			<TouchableItem onPress={this.handlePress}>
				<View style={rootStyle} testID="selector-item-root">
					<Body1 style={styles!.title}>{title}</Body1>
					{description ? <Body2 style={styles!.description}>{description}</Body2> : null}
				</View>
			</TouchableItem>
		);
	}
}
