export const components = {
	AvatarVariantSelectorItem: import('./AvatarVariantSelectorItem'),
	CityPicker: import('./CityPicker'),
	ColorVariantSelectorItem: import('./ColorVariantSelectorItem'),
	ImageVariantSelectorItem: import('./ImageVariantSelectorItem'),
	MediaSlider: import('./MediaSlider'),
	MultiLevelSelector: import('./MultiLevelSelector'),
	Price: import('./Price'),
	StatusBadge: import('./StatusBadge'),
	TextVariantSelectorItem: import('./TextVariantSelectorItem'),
	VariantSelector: import('./VariantSelector'),
};
