import { HoverObserver, HoverObserverState, View } from '@bluebase/components';
import React, { useState } from 'react';

import { ViewStyle } from 'react-native';
import { getItemComponent } from './getItemComponent';

export interface VariantSelectorItemProps {
	active?: boolean;
	hovering?: boolean;
	index: number;
	size?: 'small' | 'medium' | 'large';
	onPress?: (index: number) => void;
	style?: ViewStyle;

	[key: string]: any;
}

export interface VariantSelectorStyles {
	root: ViewStyle;
	itemRoot: ViewStyle;
}

export interface VariantSelectorProps {
	ItemComponent: string | React.ComponentType<VariantSelectorItemProps>;
	items?: any[];
	index?: number;
	size?: 'small' | 'medium' | 'large';
	onChangeIndex?: (index: number) => void;
	onChangeHover?: (index: number, isHovering: boolean) => void;
	style?: ViewStyle;
	styles?: Partial<VariantSelectorStyles>;
}

export const VariantSelector = ({
	ItemComponent,
	index,
	items,
	onChangeIndex,
	onChangeHover,
	size,
	style,
	styles,
}: VariantSelectorProps) => {
	const [hovering, setHovering] = useState(-1);
	const Item = getItemComponent(ItemComponent);

	// onHoverChange event listener
	const onHoverChanged = (i: number) => ({ isHovering }: HoverObserverState) => {
		if (isHovering === true) {
			setHovering(i);
		} else {
			setHovering(-1);
		}

		if (onChangeHover) {
			onChangeHover(i, isHovering);
		}
	};

	// Create child nodes
	const children = items!.map((item, i) => (
		<HoverObserver onHoverChanged={onHoverChanged(i)} key={i}>
			<Item
				index={i}
				active={i === index}
				hovering={i === hovering}
				onPress={onChangeIndex}
				styles={{ root: styles!.itemRoot }}
				size={size}
				{...item}
			/>
		</HoverObserver>
	));

	return (
		<View
			style={{
				...styles!.root,
				...style,
			}}
		>
			{children}
		</View>
	);
};

VariantSelector.defaultStyles = {
	root: {
		flexDirection: 'row',
		padding: 2,
	},

	itemRoot: {
		margin: 2,
	},
};

VariantSelector.defaultProps = {
	index: 0,
	items: [],
};
