import { VERSION } from './version';
import { components } from './components';
import { createPlugin } from '@bluebase/core';
import { lang } from './lang';

export * from './components/exports';

export default createPlugin({
	description: 'Commi Components',
	key: '@commi/commi-client-plugin-ui',
	name: 'Commi Client Plugin UI',
	version: VERSION,

	components,

	filters: {
		...lang,
	},
});
