import { Caption } from '@bluebase/components';
import React from 'react';
import { TextStyle } from 'react-native';
import { Theme } from '@bluebase/core';

export interface StatusBadgeProps {
	title: string;
	color?: string;
	backgroundColor?: string;
	styles?: Partial<StatusBadgeStyles>;
}

export interface StatusBadgeStyles {
	root: TextStyle;
}

export const StatusBadge = (props: StatusBadgeProps) => {
	const { title, styles, color, backgroundColor, ...rest } = props;
	return (
		<Caption
			style={{
				...styles!.root,
				backgroundColor: backgroundColor || styles!.root!.backgroundColor,
				color: color || styles!.root!.color,
			}}
			{...rest}
		>
			{title}
		</Caption>
	);
};

StatusBadge.defaultProps = {};

StatusBadge.defaultStyles = (theme: Theme): StatusBadgeStyles => ({
	root: {
		backgroundColor: '#cfd8dc',
		borderRadius: theme.shape.borderRadius,
		color: '#607d8b',
		fontWeight: 'bold',
		marginVertical: theme.spacing.unit / 2,
		paddingHorizontal: theme.spacing.unit / 2,
		paddingVertical: theme.spacing.unit / 4,
		textTransform: 'capitalize',
	},
});
